﻿/// <reference path="../main.scss" />
/**
    Slick Overrides
**/

.slick-prev{
    z-index:2;
}
.slick-next,
.slick-next:hover,
.slick-next:focus,
.slick-next:active,
.slick-prev,
.slick-prev:hover,  
.slick-prev:focus,
.slick-prev:active{
    z-index: 2;
    border-radius: 50%;
    width:24px;
    height:24px;
    background-color: $color-white;
    color: $color-accent;
    &:before{
        content:"";  
        font-size:0;
    }
    &.slick-arrow-lg{
        width:30px;
        height:30px;
    }
}
.slick-arrow-lg{
    &.slick-next{
        right: -40px;
    }
    &.slick-prev{
        left: -40px;
    }
}
.slick-arrow-md{
    &.slick-next{
        right: -13px;
    }
    &.slick-prev{
        left: -13px;
    }
}
.slick-slider{
    .icon-sm{
        width:10px;
        height:10px;
    }
    .icon-md{
        width:15px;
        height:15px;
    }
    .icon-lg{
        width:30px;
        height:30px;
    }
}