﻿@import "styles/font-roboto";
@import "styles/font-roboto-condensed";

@import "helpers/variables_base";
$color-accent: #000000;
$color-accent-dark: #D81921;
@import "helpers/variables";
@import "../bootstrap-sass-master/assets/stylesheets/_bootstrap";
@import "../slick-1.6.0/slick/slick.scss";
@import "../slick-1.6.0/slick/slick-theme.scss"; 
//@import "../cookieconsent/cookieconsent.scss"; 

@import "helpers/bootstrapOverrides";
@import "helpers/slickOverrides";

@import "styles/generic";
@import "styles/blocks";
@import "styles/pages";

.btn-primary, .contact-form-pardot p.submit input {
    background-color: #D81921;
}
.btn-primary:hover, .contact-form-pardot p.submit input:hover {
    color: #fff;
    background-color: #aa141a;
    border-color: rgba(0, 0, 0, 0);
}
.btn-primary:active, .contact-form-pardot p.submit input:active, .btn-primary.active, .contact-form-pardot p.submit input.active, .open > .btn-primary.dropdown-toggle, .contact-form-pardot p.submit .open > input.dropdown-toggle {
    color: #fff;
    background-color: #aa141a;
    border-color: rgba(0, 0, 0, 0);
}

.btn-primary:focus, .contact-form-pardot p.submit input:focus, .btn-primary.focus, .contact-form-pardot p.submit input.focus {
    color: #fff;
    background-color: #aa141a;
    border-color: rgba(0, 0, 0, 0);
}

@media (min-width: 992px) {
    .navbar-nav > li > a, .search-in-nav > .searchbox-wrapper {
        border-right: 1px solid #ffffff;
        /* height: 60px; */
    }
}

.button-mobile {
    border-left: 1px solid #ffffff !important;
}