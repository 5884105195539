/* Thin */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-thin.woff2') format('woff2'), url('/static/fonts/roboto-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-thinitalic.woff2') format('woff2'), url('/static/fonts/roboto-thinitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

/* Light */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-light.woff2') format('woff2'), url('/static/fonts/roboto-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-lightitalic.woff2') format('woff2'), url('/static/fonts/roboto-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Normal */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-regular.woff2') format('woff2'), url('/static/fonts/roboto-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-italic.woff2') format('woff2'), url('/static/fonts/roboto-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Medium */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-medium.woff2') format('woff2'), url('/static/fonts/roboto-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-mediumitalic.woff2') format('woff2'), url('/static/fonts/roboto-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-bold.woff2') format('woff2'), url('/static/fonts/roboto-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-bolditalic.woff2') format('woff2'), url('/static/fonts/roboto-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

/* Black */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-black.woff2') format('woff2'), url('/static/fonts/roboto-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto-blackitalic.woff2') format('woff2'), url('/static/fonts/roboto-blackitalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
