﻿/// <reference path="../main.scss" />
/**
    Pages
**/

.mega-menu-hide-item {
    display: none;
}

.social-media-expander-divs .panel-heading a {
    color: $color-black;

    svg {
        color: $color-accent;
    }
}
///////////////////////////////*Contact us Page*////////////////////////////////////
.location-mobile {
    top: 10px;
    right: 0;
    position: absolute;
    margin-right: 40px;
    cursor: pointer;
}

.contact-us-page {
    margin-top: 20px;

    &__heading {
        font-family: Roboto;
        font-size: 36px;
        font-weight: 700;
        font-family: $font-family-sans-serif-condensed;
        line-height: 1.22;
        letter-spacing: -1px;
        text-align: center;
        color: #111111;
    }



    &__text {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.56;
        text-align: center;
        color: #111111;
    }
}

.country-bg-modifier {
    background: #eee;
}

.no-border-bottom {
    border-bottom: 0 !important;
}

.office_locations__list {
    display: none;

    @media (min-width: 992px) {
        display: block;
    }
}


.office_locations_countrypage_link {
    cursor: pointer;
}

.office_locations {
    box-shadow: none;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    /*height: 672px;*/
    @media (min-width: 992px) {
        overflow-x: hidden;
        max-height: 672px;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        overflow-y: scroll;
        width: 344px;
        height: 100%;
    }

    &__title-bg {
        height: 48px;
        /*border: 1px solid #eee;*/
        @media (min-width: 992px) {
            width: 344px;
            background-color: $color-accent;
        }
    }

    &__title {
        padding-left: 24px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 700;
        font-family: $font-family-sans-serif-condensed;
        line-height: 2;
        letter-spacing: 1px;
        color: $color-accent;
        text-transform: uppercase;

        @media (min-width: 992px) {
            color: #ffffff;
        }

        a {
            cursor: pointer !important;
        }
    }

    &__item {
        padding-left: 24px;
        font-family: Roboto;
        font-size: 18px;
        font-family: $font-family-sans-serif-condensed;
        line-height: 2.67;
        color: #111111;
    }
    /*&__item:not(:last-child) {
        border-bottom: 1px solid #dddddd;
    }*/
    &__heading {
        position: relative;
        /*background: #eee;*/
        padding-left: 24px;
    }
}

.countrypage:not(:last-child) {
    border-bottom: 1px solid #dddddd;
}

.officepage:not(:last-child) {
    border-bottom: 1px solid #dddddd;
}

.modifier {
    padding-left: 0px;
}

.officepage {
    background: #fff;
    padding-bottom: 15px;

    &__title {
        padding-left: 24px;
        /*width: 304px;*/
        height: 20px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        font-family: $font-family-sans-serif-condensed;
        line-height: 1.25;
        color: $color-accent;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    &__description {
        padding-left: 24px;
        /*width: 304px;*/
        /*height: 40px;*/
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.43;
        color: #111111;
    }

    &__description-row {
        padding-top: 5px;

        p {
            margin-bottom: 0px;
        }
    }
}

#wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 90px;
}

#map {
    height: 0;
    overflow: hidden;
    padding-bottom: 35%;
    padding-top: 30px;
    position: relative;
    margin-top: 30px;
    margin-top: 70px;

    @media (min-width: 992px) {
        margin-top: 0px;
    }
}

#over_map {
    position: relative;
    top: 10px;
    z-index: 99;
    left: 0px;
    border: 1px solid #eee;

    @media (min-width: 992px) {
        position: absolute;
        left: 30px;
    }
}

.location__breadcrumb {
    position: absolute;
    z-index: 999;
    margin-left: 15px;
    margin-top: 15px;

    @media (min-width: 992px) {
        margin-left: 400px;
    }


    ul {
        justify-content: space-between;
        width: 100%;
        list-style: none;
        border: 3px solid#ddd;
        padding-left: 0px;
        margin-top: 10px;

        li {
            background: $color-accent;
            padding: 5px 10px;
            margin-right: 1px;
            cursor: pointer;

            a {
                color: #fff;
            }
        }
    }
}

.infowindows-dark {
    background-color: #333 !important;
    top: 0 !important;
    left: 0 !important;
    padding: 5px 10px;
    color: #fff;
    font-size: 16px;
    display: block !important;
}
///////////////////////////////End contact page///////////////////////////////////////
//////////////////////////////*Office Page*//////////////////////////////////////////
.flex {
    display: flex;
}

.officepage-container {
    padding-bottom: 48px;

    &__contactblock-divider {
        margin-top: 30px;
        margin-bottom: 30px !important;
        border-bottom: 1px solid #ddd;

        svg {
            color: $color-accent-dark;
        }
    }

    &__block-box {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}


/////////////////////////////End office page///////////////////////////////////////
/////////////////////////Accessoriespage///////////////////////
.accessoriespage {
    &__heading {
        font-size: 28px;
        font-weight: 600;
        line-height: 1;
        color: #333;
    }

    img {
        width: 100%;
    }

    ol {
        padding-left: 10px;

        li {
            list-style: none;
        }
        /*li:before {
            content: "";
            position: relative;
            left: -10px;
            background-color: red;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }*/
    }

    .table-responsive {
        margin-top: 10px;
        overflow-x: inherit;
    }

    thead {
        background: #eeeeee;
    }

    tbody:nth-child(odd) {
        background: #eeeeee;
    }

    tbody:nth-child(even) {
        background: #FFF;
    }

    &__container {
        padding-left: 25px;
        padding-top: 25px;

        @media (min-width: 768px) {
            padding: 0px;
        }
    }
}

.accessories-list-item {
    margin-bottom: 10px;
}


////////////////////////End///////////////////////////////////
.standard-page-padding {
    padding-top: ($margin*2);
    padding-bottom: ($margin*4);

    .headline {
        margin-bottom: 25px;
    }

    .share-block {
        margin-top: 10px;
    }
}

.standard-page-padding-lg {
    padding-top: ($margin*6);
    padding-bottom: ($margin*6);

    @media (max-width: $screen-md-max) {
        padding-top: ($margin*3);
        padding-bottom: ($margin*3);

        h2 {
            margin-bottom: ($margin*3);
        }
    }
}

.standard-page-padding-reset {
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: 768px) {
        padding-top: ($margin*2);
        padding-bottom: ($margin*4);
    }
}

footer {
    background-color: $color-black-light;
    /*padding-top:($margin * 4);
    @media (max-width: $screen-xs-max) {
        padding-top:($margin * 1.66);
    }*/
    .big-footer {
        //height: 240px;
        padding-top: ($margin * 4);
        margin-bottom: ($margin * 4);

        @media (max-width: $screen-xs-max) {
            padding-top: ($margin * 1.66);
            min-height: 0;
            margin-bottom: 0;

            .panel-heading a {
                color: $color-black;

                .icon {
                    color: $color-accent;
                }
            }

            .panel-body a {
                color: $color-accent;
            }
        }

        .heading {
            font-size: 20px;
            font-weight: 700;
            font-family: $font-family-sans-serif-condensed;
            color: #ffffff;
            margin-bottom: 12px;
            display: block;
        }

        .inner {
            /*width: 74px;
            height: 24px;*/
        }

        li a {
            font-size: 15px;
            line-height: 1.6;
            color: #ffffff;
        }
    }

    .small-footer {
        background-color: $color-black;

        .footer-links {
            margin-top: 14px;
            margin-bottom: 14px;
            /*height: 16px;*/
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            color: #ffffff;
        }

        .links {
            /*height: 16px;*/
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            color: #ffffff;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                overflow: hidden;
            }

            li {
                display: inline;
                margin-right: ($margin*2);
            }

            a {
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                color: #ffffff;
            }
        }

        .info {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            color: $color-gray-dark;
        }
    }
}

@media (max-width: 767px) {

    .news-list-selectors {
        margin-bottom: 15px;
    }

    footer .small-footer .footer-links {
        text-align: center;

        .info {
            padding-top: 5px;
        }

        .links {
            padding-top: 34px;
            padding-bottom: 34px;
        }
    }
}

//
// The following code is an attempt to style a form in which we have no control over the markup.
// A lot of overwrites and !important statements.
//
.newsletter-form {
    background-image: url(/globalassets/images/home/newsletterbackground.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    // Put these back in when Dexion have all their newsletter forms in place
    /*padding-top: 60px;
    padding-bottom: 60px;*/
    form#clickdimensionsForm {
        height: auto;
    }

    u {
        text-decoration: none;
    }

    a span {
        color: $color-accent !important;
        text-decoration: none !important;

        &:hover {
            color: $color-accent-dark !important;
            text-decoration: underline !important;
        }
    }

    span {
        color: white !important;
    }

    .responsiveCellSize1 {
        width: auto;
        min-width: auto;
    }

    .clickdform {
        &.mainDiv {
            height: auto;
        }

        .requiredStar {
            color: red !important;
        }

        input[type="text"] {
            padding: 10px;
            font-size: 16px;
            color: #999999;
            font-family: "Roboto", sans-serif !important;
            border: none;

            @media (max-width: 680px) {
                width: 100% !important;
                border-radius: 0 !important;
            }
        }

        input[type="checkbox"] {
            float: none;
            margin-right: 10px;
        }

        input[leadfield="firstname"] {
            border-radius: 60px 0 0 60px;
            border-right: 1px solid #dddddd;
        }

        input[leadfield="emailaddress1"] {
            border-radius: 0 60px 60px 0;
            border-left: 1px solid #dddddd;
        }

        input#btnSubmit {
            border: none;
            padding: 12px 28px 10px 28px;
            border-radius: 60px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 18px !important;
            letter-spacing: 1px;
            font-family: "Roboto Condensed" !important;
            color: $color-white !important;
            background-color: $color-accent !important;

            &:hover {
                background-color: $color-accent-dark !important;
                color: $color-white !important;
            }
        }

        .buttonContainer {
            margin-right: 0 !important;
        }
    }
}

.contact-us-raw-block {
    text-align: center;

    @media (max-width: 600px) {
        height: 150%;
    }

    .responsiveCellSize1, .responsiveCellSize3 {
        text-align: left;
    }

    .clickdform.mainDiv * {
        font-size: 12px !important;
    }

    .clickdform.mainDiv select {
        height: 45px;
    }
     
    input[type="text"] {
        @media (max-width: 680px) {
            width: 100% !important;
        }
    }

    input#btnSubmit {
        border: none;
        padding: 12px 28px 10px 28px;
        border-radius: 60px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px !important;
        letter-spacing: 1px;
        font-family: "Roboto Condensed" !important;
        color: $color-white !important;
        background-color: $color-accent !important;

        &:hover {
            background-color: $color-accent-dark !important;
            color: $color-white !important;
        }
    }

    .buttonContainer {
        margin-right: 0 !important;
    }
}
// Some more styles for the forms
.clickdform .minSize3 {
    min-width: unset !important;
}
.clickdform .responsiveCellSize3 {
    min-width: unset !important;
    width: unset !important;
}

////////////////////////////////////////////////////////////////
.textbox-input {
    font-family: "Roboto" !important;
    font-weight: 400;
    font-size: 13px !important;
    color: #333;
    margin: 0;
    padding: 2px 6px;
    border-top: 1px solid #7c7c7c;
    border-left: 1px solid #c3c3c3;
    border-right: 1px solid #c3c3c3;
    border-bottom: 1px solid #ddd;
    width: 80%;
}

.formcheckbox {
    font-family: Roboto-Light;
    font-size: 16px;
    line-height: 24px;
    color: #111;
    padding-left: 6px;
}

.pdf-form-cell {
    margin-bottom: 10px;
}

.pdf-form-error {
    border-color: red !important;
}

.pdf-form-show-error {
    color: red;
    height: 30px;
}
