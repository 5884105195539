﻿/// <reference path="../main.scss" />
/**
    Variables
**/

$text-color: $color-black;  
$brand-primary: $color-accent;
$link-hover-color: $color-accent-dark;

$navbar-default-bg: $color-accent;
$navbar-default-border: $color-accent-dark;
$navbar-default-color: $color-white;
$navbar-default-link-color: $color-white;
$navbar-default-link-hover-color: $color-white;
$navbar-default-link-active-color: $color-white;

$navbar-default-link-hover-bg: $color-accent-dark;
$navbar-default-link-active-bg: $color-accent-dark;

$input-border-focus: $color-accent;

$well-border: $color-gray;
$well-bg: $color-white;

$blockquote-border-color: $color-accent;

$modal-content-bg: $color-gray-light-light;

$border-radius-base:        2px !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;
$pager-border-radius:       0 !default;

$sidebar-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);

$grid-gutter-width: 24px !default;  
$container-large-desktop:      (1224px + $grid-gutter-width) !default;
$screen-lg:                     (1320px) !default;


$margin: 12px;

$margin-sidebar-vertical: ($margin * 2);
$margin-sidebar-horizontally: $margin;
@mixin sidebar-margin(){
    margin:$margin $margin-sidebar-vertical;
}

//Button
$btn-primary-border: transparent;

//Icons
$icon-default: 1em;
$icon-xs: $icon-default / 2;
$icon-small: $icon-default;
$icon-medium: $icon-default * 1.2;
$icon-large: $icon-default * 1.6;
$icon-xlarge: $icon-default * 2;
$icon-xxlarge: $icon-default * 3;