//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  font-size: ($font-size-base * 1.5);
  font-weight: $close-font-weight;
  line-height: 1;
  color: $close-color;
  text-shadow: $close-text-shadow;
  @include opacity(.2);

  &:hover,
  &:focus {
    color: $close-color;
    text-decoration: none;
    cursor: pointer;
    @include opacity(.5);
  }

  // [converter] extracted button& to button.close
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
/*button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  z-index:1060;
  position:relative;
  margin-right:2px;
  margin-top:-2px;
}*/

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    z-index: 1060;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    opacity: 1;
    border-radius: 100%;
    line-height: 24px;
    color: $brand-primary;

      &:hover,
      &:focus {
        color: $close-color;
        text-decoration: none;
        cursor: pointer;
        opacity: 1;
      }
}
