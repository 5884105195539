﻿/// <reference path="../main.scss" />

.background-0 {
    background-color: $color-white;
}

.background-1 {
    background-color: $color-gray-light;
}

.background-2 {
    background-color: $color-accent;
}

.accent-color {
    color: $color-accent;
}

.image-wrapper{

}

body {
    /*background-color: $color-black;*/
    background-color: $color-white;

    &.mceContentBody { //TinyMCE
        background-color: $color-white;
    }
}

#maincontent {
    background-color: $color-white;
}

.container {
    @media (max-width: $screen-md-max) {
        &.container-md-max-xl {
            padding-left: $padding-base-horizontal*3;
            padding-right: $padding-base-horizontal*3;
            //width: $container-md*2;
        }
    }
    /*@media (min-width: $screen-sm-min) {
    //width: $container-sm;
    }
    @media (min-width: $screen-md-min) {

    }
    @media (min-width: $screen-lg-min) {
    //width: $container-lg;
    }*/
}

.main-content-padding {
    padding: ($margin*4) 0 0;

    @media (max-width: $screen-md-max) {
        padding: ($margin*2) 0;
    }

    @media (max-width: $screen-sm-max) {
        padding: 0;
    }
}

// Text styles
// Headlines
.headline-margin {
    margin-bottom: ($line-height-computed / 2);
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: $margin*2;
}

.headline {
    /*font-size: 20px;*/
    font-weight: 700;
    font-family:$font-family-sans-serif-condensed;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: $color-black;
}

article {
    font-size: 16px;
    line-height: 1.5;
    padding-bottom:48px;
}

.text-uppcase-style {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-family:$font-family-sans-serif-condensed;
    letter-spacing: 1px;

    @media (min-width: 992px) {
        font-size:13px;
        }

}

.text-center-md-max {
    @media (max-width: $screen-md-max) {
        text-align: center;
    }
}

.text-center-small {
    @media (max-width: $screen-sm) {
        text-align: center;
    }
}

.flex-h-center {
    display: flex;
    align-items: center;
}

.flex-v-center {
    display: flex;
    align-items: center;
}

.flex-right {
    margin-left: auto;
}
@supports (display: flex) {
    .flex-row {
        //display:block;
        //@supports (display: flex) {
        //}
        &.row {
            display: flex;
            flex-wrap: wrap;

            &:after,
            &:before {
                display: flex;
            }

            > [class*='col-'] {
                display: flex;
                flex-direction: column;
            }
        }



        .thumbnail {
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px #dddddd;
        }

        .thumbnail {
            flex: 1 0 auto;
            flex-direction: column;
            padding: 6px;
        }

        .flex-text {
            flex-grow: 1;
        }

        img {
            height: auto;
            width: 100%;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            /*a{
            color:$text-color;
        }*/
        }
    }
}
.height-100 {
    height: 100%;
}

.pos-r {
    position: relative;
}

.article-margin {
    margin: ($margin*3) ($margin*3) ($margin*4);
}

.init-standard-carousel {
    margin-bottom: $margin;

    .slick-prev, .slick-next {
        top: calc(50% - 20px); //Half of margin-bottom + text.
    }

    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }
}

.link-block:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media screen and (min-width:0\0) { //IE 10/11 hack to make sure text is not displyed on top of the linkblock.
        background: url('about:blank');
    }
}

.panel-box {
    padding: $margin;
    background-color: $color-accent;
    color: $color-white;

    .heading {
        font-size: $font-size-h3;
        margin-bottom:12px;
    }
    .attributes-selector{
        .dropdown-menu{
            left:12px;
        }

    }
}

.slider-pre-init img:nth-child(n+2) { // NOTE: Hides all but the first image
    display: none;
}

.slider-pre-init .carousel-item{
    display: none;
}

.slider-pre-init .large-carousel-item{
    display: none;
}



.large-carousel-with-nav {
    .slider-present {
        margin-bottom: $margin*2;
    }

    .well {
        margin-left: $margin/2;
        margin-right: $margin/2;
        padding: 5px;
        box-sizing: border-box;

        &.slick-current {
            border: solid 3px $color-accent;
            padding: 3px;
        }

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }

    .slick-prev, .slick-next {
        //top:calc(50% + #{$margin}); //Half of margin-bottom.
        .product-page & {
            top: calc(50% - #{$margin});
        }
    }
}

.panel-heading {

    a {
        .collapse {
            display: block;
        }

        .expand {
            display: none;
        }

        &.collapsed {
            .collapse {
                display: none;
            }

            .expand {
                display: block;
            }
        }
    }
}

.panel-expander {
    font-size: $font-size-h5;
    font-weight: bold;

    .icon {
        float: right;
        margin-top: 6px;
    }
}

.divider-line {
    height: 12px;
    background-color: $color-accent;

    &::after {
        content: "";
        display: block;
        background-color: $color-white;
        width: 10px;
        height: 100%;
        margin-left: ($margin * 1.5);
    }

    &.solid {
        &::after {
            content: none;
        }
    }

    &.small {
        height: 6px;
        margin-bottom: 12px;

        &::after {
            width: 6px;
        }
    }
}

.divider-line-gray {
    height: 1px;
    background-color: $color-gray;
}

.share-block {
    font-size: 14px;
    font-weight: bold;

    .text {
        margin-right: (floor($margin));
    }

    a:not(:last-of-type) {
        margin-right: 0px;
    }

    .small {
        //All current styles used are the same but have .small added for future needs (it's small)
    }

    &.article-margin {
        margin-right: -($margin*2);
    }
}

.main-box {
    padding: $margin;
    margin-bottom: $margin*4;

    @media (max-width: $screen-sm-max) {
        margin-bottom: $margin*2;
    }

    box-shadow: $sidebar-box-shadow;
    border: solid 1px $color-gray;

    .headline {
        margin-bottom: $margin*2;
    }

    .divider-line, .link-bar {
        margin-left: -($margin);
        margin-right: -($margin);
    }
}

.link-bar {
    background-color: $color-accent;
    color: $color-white;
    display: block;
    padding: $margin-sidebar-horizontally $margin-sidebar-vertical;
    //margin-bottom: -($margin-sidebar-horizontally);
    display: flex;
    align-items: center;

    .link-text {
        margin-right: auto;
        display: block;
    }

    &:hover, &:focus, &:active{
        background-color: $color-accent-dark;
        color: $color-white;
    }
}




//Force the link text to remain white
a{ 
    &:hover, &:focus, &:active{
        //color:$color-white !important;
    } 
}


.sidebar-box {
    background-color: $color-white;
    padding: $margin-sidebar-horizontally $margin-sidebar-vertical;
    margin-bottom: ($margin * 2);
    box-shadow: $sidebar-box-shadow;
    border: solid 1px $color-gray;

    .link-bar {
        margin-bottom: -($margin-sidebar-horizontally);
    }

    .divider-line, .link-bar {
        margin-left: -($margin-sidebar-vertical);
        margin-right: -($margin-sidebar-vertical);
    }

    .sub-heading {
        text-align: center;
        font-family:$font-family-sans-serif-condensed;
        @extend .h5;
    }

    .videowithlightboxblock & {
        padding-bottom: 0;
    }
}

.video-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $margin;
}

.play-icon {
    position: absolute;
    font-family: 'Glyphicons Halflings';
    color: $color-white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    right: calc(50% - 30px);
    background-color: rgba(17,17,17,0.81);

    &::after {
        content: "\e072";
        font-size: 20px;
        line-height: 0.9;
        margin-left: 3px;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $color-white;
        background-color: $color-black;
        outline:0;
    }

    &.play-icon-lg {
        width: 80px;
        height: 80px;

        &::after {
            font-size: 30px;
            margin-left: 3px;
        }
    }
}

.vr-icon {
    position: absolute;
    font-family: 'Glyphicons Halflings';
    color: $color-white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    right: calc(50% - 30px);
    background-color: rgba(17,17,17,0.81);

    &::after {
        content: "\e046";
        font-size: 20px;
        line-height: 0.9;
        margin-left: 0;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $color-white;
        background-color: $color-black;
        outline: 0;
    }

    &.play-icon-lg {
        width: 80px;
        height: 80px;

        &::after {
            font-size: 30px;
            margin-left: 3px;
        }
    }
}

.link-block::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.icon {
    vertical-align: text-top;

    &.stroke {
        stroke: currentColor;
        fill: none;
    }

    &.fill {
        fill: currentColor;
        stroke: none;
    }
}

.icon-middle {
    vertical-align: middle;
}

.icon-xs {
    width: $icon-xs;
    height: $icon-xs;
}

.icon-sm {
    width: $icon-small;
    height: $icon-small;
}

.icon-md {
    width: $icon-medium;
    height: $icon-medium;
}

.icon-lg {
    width: $icon-large;
    height: $icon-large;
}

.icon-xl {
    width: $icon-xlarge;
    height: $icon-xlarge;
}

.icon-before {
    margin-right:5px;
}

.icon-link-list {
    .icon {
        margin-right: $margin;
    }

    li {
        margin-bottom: $margin;
    }
}

.image-link-list {
    a:hover {
        text-decoration: none;

        .text-uppcase-style {
            text-decoration: underline !important;
        }
    }

    li:not(:last-child) {
        margin-bottom: $margin * 2;
    }

    .text {
        color: $color-black;
    }
}

.info-card-outer {
    margin-bottom: $margin*2;
}

.info-card {
    /*padding:$margin;*/
    
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #DDD;
    @supports (display: flex){
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
    }

    .info-card-inner {
        background-color: $color-white;
        padding: $margin;
        height: 100%;
    }

    .caption {
        padding: $margin;

        p {
            margin-bottom: $margin;
        }
    }

    .headline {
        font-size: 20px;
        margin-bottom: 12px !important;
    }
}

.right-navigation {
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);

    a {
        color: $color-black;

        &.active {
            color: $color-accent;
        }
    }

    .header {
        background-color: $color-accent;
        padding: $margin-sidebar-horizontally $margin-sidebar-vertical;
        color: $color-white;
        margin-bottom: 0;

        a {
            color: currentColor;
        }
    }

    li {
        > a {
            padding: $margin-sidebar-horizontally $margin-sidebar-vertical;
            display: block;
        }
        /*box-shadow: $sidebar-box-shadow;*/
        border: solid 1px $color-gray;
        border-top: none;
    }

    ul.second-level {
        padding-left: 1em;

        > li {
            border: none;

            a {
                padding-top: 0;
            }
        }
    }

    .expander {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        padding: 16px 24px 0;
        border-left: solid 1px $color-gray;
        font-size: 8px;
        color: $color-accent;
    }
}

.list-selectors-wrapper {
    margin-bottom: $margin*4;
}

.unordered-item-list {
    time {
        font-size: 12px;
        line-height: 2;
    }

    .headline {
        font-size: 20px;
        color: $color-accent;
    }

    .caption {
        margin-top: 3px;
    }

    .item:hover {
        .headline {
            text-decoration: underline;
        }
    }
}

.file-list-wrapper {
    .item {
        margin-bottom: $margin*4;
    }

    .action-links {
        a, button {
            margin-right: $margin;
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-base*0.92857142; //13.0px :)
        }

        .icon {
            margin-right: $margin/2;
        }
    }

    p {
        margin-bottom: 15px;
    }

    .thumbnail {
        color: $color-white;
        margin-bottom: 0;

        .icon {
            background-color: $color-gray-light-light;

            img {
                width: 48px;
                padding: $margin*3 0;
                margin: 0 auto;
            }
        }
    }
}

.searchbox {
    text-align: center;
}


.search-input {
    width: 100% !important;
    height: 40px !important;
    font-size: 15px;
}

.search-icon {
    margin-right: 40px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    color: $color-accent;
}

.search-result-form {
    margin-bottom: 10px;
}

.searchpage {
    margin-left: 0px;
    margin-right: 0px;

    @media (min-width: 992px) {
        margin-left: -12px;
        margin-right: -12px;
    }
}

.dropdown-menu-whole-width {
    margin-left: -24px;
    margin-right: -24px;
}

.dropdown-menu-columns {
    column-count: 3;
    min-width: 400px !important;
    left: calc(50% - 400px) !important;

    > li {
        break-inside: avoid;
        overflow: hidden;
    }
}
.market-picker-heading {
    border-bottom: 1px solid $color-black-light;
    padding-bottom: 5px;
}
.market-picker-link {
    border-bottom: 1px solid $color-gray-2;
    display: block;
    margin-bottom: 15px;
     
    &:hover, &:active, &:focus {
        text-decoration: none;
    }
} 
.market-picker-group {
    margin-bottom: 100px;
}
.js-sub-dropdown {
    padding: 0 5px 20px 10px;

    @media (min-width: $screen-sm-min) {
        z-index: 2;
        position: absolute;
        top: 24px;
        padding: 5px 5px 5px 10px;
        background-color: #eee !important;
        box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.25);
    }
}
.navbar-default {
    @media (max-width: $screen-xs-max) {
    max-height: 600px;
    overflow-y: scroll;
    }
}
.search-mobile {
    right: 72px;
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: $color-accent;
    border-right: 1px solid #777777;
    color: #fff;
    cursor: pointer;
}

.search-mobile:hover, .search-mobile:active, .search-mobile:focus {
    background-color: $color-accent-dark !important;
    color: #fff;
}

.search-mobile-dropdown {
    right: 72px;
    position: absolute;
    width: 72px;
    height: 72px;
    background-color: $color-accent;
    /*border-right: 1px solid #777777;*/
    color: #fff;
    cursor: pointer;
    color: #fff;
    /* right: 99px; */
    margin: auto 0;
    position: absolute;
    text-align: center;
    margin-top: -36px;

    svg {
        margin: 25px;
    }

    &:hover, &:focus, &:active {
        background-color: $color-accent-dark !important;
        color: #fff;
    }
}

.button-mobile {
    background: $color-accent !important;
    border-left: 1px solid $color-accent-dark !important;
    border-bottom: none;
    border-top: none;
    border-right: none;
    z-index: 200000000000;
    right: 0;
    position: absolute;
    height: 72px;
    margin: 0px;
    width: 72px;
    right: 0px;
}

.search-mobile-menu {
    margin-top: 0px !important;
    border-top: none !important;
}

.expand-plucicon {
    top: 14px;
    right: 30px; 
    position: absolute;
    font-size: 20px;
    font-family:$font-family-sans-serif;
}

.mobilenav-border {
    top: 0px;
    height: 100%;
    position: absolute;
    right: 71px;
    background-color:#ddd;
    width: 1px; 
}



@media (min-width: 768px) {
    .mobilenav-border {
        right: 71px;
    }

    .expand-plucicon {
        right: 30px;
    }
}

@media (min-width: 992px) {
    .mobilenav-border {
        right: 71px;
    }

    .expand-plucicon {
        right: 30px;
    }
}

@media (max-width: 768px) {
    .logo {
        max-width: 45%;
    }
}


.logo {
    /*width: 100%;
    height: 100%;*/
    img {
        max-width: 300px;
        width: 100%;
        height: 100%;

        @media (max-width: $screen-md-max) {
            max-width: 200px;
        }
        /*width: auto;
        height: 100%;*/
        /*width:100%;
        @media (min-width: 310px) { 
            max-width: 150px;
        }

        @media (min-width: 375px) {
            max-width: 200px;
        }

        @media (min-width: 450px) { 
            max-width: 250px;
        }

        @media (min-width: 500px) {
            max-width: unset;
        }*/
    }
}

    .navbar-right li a .mobilenav-border {
        /*right: 84px;*/
    }

    .navbar-right li a .expand-plucicon {
        /*right: 47px;*/
    }

    .dropdown-menu-mobile {
        border: none !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 25px !important;
        margin-top: 0px !important;
        background-color: #fff !important;

        @media (min-width: 992px) {
            left: 24px !important;
            right: 24px;
            margin-top: 12px !important;
            border-top: 5px solid $color-accent !important;
            padding-top: 36px !important;
            padding-bottom: 36px;
            background-color: #EEE !important;
        }

        &__item {
            padding-top: 10px;
            padding-bottom: 10px;

            @media (min-width: 992px) {
                margin: 0px;
                padding-top: 0px;
            }
        }

        &__icon {
            color: $color-accent;
            position: absolute;
            right: 52px;
            top: 12px;
        }
    }

    .dropdown-menu-mobile li {
        border-bottom: none !important;

        a.current {
            color: $color-accent;
        }
    }

    .dropdown.fw .heading {
        color: #111 !important;
        font-weight: 400 !important;
        font-size: 17px;
        margin-bottom: 12px;
        display: block;

        @media (min-width: 992px) {
            color: $color-accent !important;
            font-size: 20px !important;
            font-weight: bold !important;
        }
    }

    .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
        background-color: transparent;
        color: #FFF;

        @media (min-width: 992px) {
            background-color: $color-accent-dark;
        }
    }

    ul.dropdown-menu.dropdown-menu-lg {
        background: #fff !important;

        li {
            border-bottom: none !important;

            a {
                /*color: #111 !important;*/
                font-weight: 400 !important;
                padding: 10px 12px 10px 40px !important;

                @media (max-width: $screen-xs-max) {
                    padding: 4px 0px 4px 5px !important;
                }

                @media (min-width: 992px) {
                    font-size: 14px !important;
                    padding: 18px 12px !important;
                }
            }
        }
    }

    .visable-in-mobile {
        font-size: 14px;
        color: #111 !important;
        padding-left: 5px;

        @media (min-width: 992px) {
            font-size: 11px;
            color: #fff !important;
        }
    }

    .text-right {
        margin-bottom: 20px;

        a {
            color: $color-accent !important;
        }
    }

    .heading-center-mobile {
        text-align: center;
        padding-bottom: 20px;

        @media (min-width: 768px) {
            text-align: left;
            padding-bottom: 0px;
        }
    }
    //Make the hamburgare icon for mobile customised
    .navbar-toggle {

        .icon-bar {
            width: 22px;
            transition: all 0.2s;
            margin-left: 15px;
        }

        .top-bar {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }

        .middle-bar {
            opacity: 0;
        }

        .bottom-bar {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
        }
    }

    .navbar-toggle.collapsed {
        .top-bar {
            transform: rotate(0);
            margin-left: 10px;
        }

        .middle-bar {
            opacity: 1;
            margin-left: 10px;
        }

        .bottom-bar {
            transform: rotate(0);
            margin-left: 10px;
        }
    }

    .social-share-mobile {
        margin: 15px 15px 0 0;
    }

    .hr-dark-gray {
        border: 1px solid #d8d8d8;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    ul.with-tick-icon {
        list-style: none;
        padding-left: 0;
    }

    ul.with-tick-icon li span:before, ol.with-tick-icon li:before {
        content: '\2713';
        display: inline-block;
        color: red;
        padding: 0 6px 0 0;
        font-weight: bold;
    }

    .inline-block {
        display: inline-block;
    }

    .plus {
        color: $color-accent;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        .border-bottom {
            border-bottom: 1px solid #ddd;
            margin-bottom: 22px;
        }
    }
    /* Fixes for WYSIWYG */
    .fix-img-width img {
        @extend .img-responsive;
    }
    /* fix the space-span of the link in link-block */
    .fix-link-block {
        position: relative;
    }

    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    .embed-container iframe, .embed-container object, .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .hp {
        display: none;
    }

    .modal-body-contact-iframe {
        min-height: 500px;
    }

    .promomodal {
        border: solid 1px $color-black-light;

        .modal-content {
            background-color: $color-white;
        }

        .modal-header {
            border-bottom: none;
            padding: 5px 0 15px
        }

        .modal-title {
            text-align: center;
        }

        .modal-title-small {
            font-size: 16px;
        }

        .modal-title-large {
            font-size: 20px;
        }

        .modal-body {
            background-color: #ECECEC;
            margin: 15px;
            padding: 15px 15px 5px 15px;
        }

        &.modal {
            text-align: center;
        }

        @media (min-width: 768px) {
            &.modal:before {
                display: inline-block;
                vertical-align: middle;
                content: " ";
                height: 100%;
            }
        }

        .modal-dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;

            @media (min-width: $screen-sm-min) {
                width: 700px;
            }

            @media (min-width: $screen-md-min) {
                width: 768px;
            }

            @media (min-width: $screen-lg-min) {
                width: 960px;
            }
        }

        @media (max-width: $screen-sm-max) {
            .btn {
                padding: 10px 12px;
            }
        }

        .close-link {
            white-space: pre;
        }

        .small-print {
            p {
                font-size: 10px;
                text-align: center;
                margin: 0;

                a {
                    color: inherit;
                    font-weight: bold;
                }
            }
        }
    }

    .contact-form-pardot {
        font-size: 14px;

        p {
            margin-bottom: 10px;
            font-size: 14px;
        }

        .required label.field-label:after {
            content: "*";
        }

        input[type="text"], textarea, select {
            float: right;
            width: 245px;
            @extend .form-control;
        }

        .pd-textarea {
            &::after {
                content: "";
                display: table;
                clear: both;
            }

            textarea {
                resize: none;
                height: 150px;
                font-size: 12px;
            }
        }

        p.submit {
            text-align: right;
            margin-top: 10px;

            input {
                @extend .btn;
                @extend .btn-primary;
            }

            @media (max-width: $screen-xs-max) {
                text-align: center;
            }
        }

        @media (max-width: $screen-md-max) {
            input[type="text"], textarea, select {
                float: none;
            }

            label {
                width: 100%;
            }
        }

        @media (max-width: $screen-md-max) {
            p {
                margin-bottom: 15px;
            }

            input[type="text"], textarea {
                float: none;
                width: 100%;
            }
        }

        @media (min-width: $screen-sm-min) {
            div.form-col-1 {
                float: left !important;
                clear: left !important;
                width: 48% !important;
                padding-left: 0 !important;
                margin-right: 0 !important;
                margin-left: 0 !important;
                padding-bottom: 10px;
            }

            div.form-col-2 {
                float: right !important;
                clear: right !important;
                width: 48% !important;
                padding-right: 0 !important;
                padding-left: 0 !important;
                margin-right: 0 !important;
                margin-left: 0 !important; 
                padding-bottom: 10px;
            }
        }
    }

.download-list-section {
    img {
        height: 160px;
        margin: auto;
    }

    .link-text {
        white-space: nowrap;
        overflow: hidden;
    }

    .icon {
        position: absolute;
        right: 26px;
    }

    .link-bar {
        padding: 12px 34px 12px 24px;
    }
}

.tab-navigation {
    ul {
        background-color: $color-accent;
        margin-bottom:20px;
    }

    li {
        display: inline-block;
        border-right: 1px solid $color-accent-dark-extra;
        color: $color-gray-light;
        padding: 10px;

        @media (max-width: $screen-sm-max) {
            display: block;
            &:not(:last-child){
                border-bottom: 2px solid $color-accent-dark-extra;
            }
        }
    }

    a {
        color: lighten( $color-accent, 25% );

        &:hover {
            color: $color-white;
        }
    }

    a.active {
        color: $color-white;
    }
}