/* Light */
@font-face {
    font-family: 'Roboto Condensed';
    src: url('/static/fonts/robotocondensed-light.woff2') format('woff2'), url('/static/fonts/robotocondensed-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/static/fonts/robotocondensed-lightitalic.woff2') format('woff2'), url('/static/fonts/robotocondensed-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Normal */
@font-face {
    font-family: 'Roboto Condensed';
    src: url('/static/fonts/robotocondensed-regular.woff2') format('woff2'), url('/static/fonts/robotocondensed-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/static/fonts/robotocondensed-italic.woff2') format('woff2'), url('/static/fonts/robotocondensed-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: 'Roboto Condensed';
    src: url('/static/fonts/robotocondensed-bold.woff2') format('woff2'), url('/static/fonts/robotocondensed-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/static/fonts/robotocondensed-bolditalic.woff2') format('woff2'), url('/static/fonts/robotocondensed-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}