﻿/// <reference path="../main.scss" />

/**
    Bootstrap Overrides
**/
//Headlines
h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: 0;
    font-weight: 700;
    font-family:$font-family-sans-serif-condensed;
}

h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: 0;
    font-family:$font-family-sans-serif-condensed;
}
//Edge to edge
.container-fluid { // TODO: Bad Bear :) Fix markup instead of zeroing out the container padding
    padding-left: 0;
    padding-right: 0;
}

.row { // TODO: Bad Bear :) Fix markup instead of zeroing out the container padding
    margin-left: 0;
    margin-right: 0;
}

//Fix nested rows !to do fix markup
div[class^="col"] .row {
    margin-left:-12px;
    margin-right:-12px;
}

.full-width {
    padding-left: 0;
    padding-right: 0;
}

.panel-group {
    .panel-default {
        background-color: $color-gray-light;

        > .panel-heading {
            background-color: $color-gray-light;
        }
    }
}

.lead {
    line-height: 1.5;
    font-weight: 700;
    font-family:$font-family-sans-serif-condensed;

    @media (min-width: $screen-sm-min) {
        font-size: floor(($font-size-base * 1.45));

        p {
            font-size: floor(($font-size-base * 1.45));
        }
    }
}

.well-xs {
    padding: 6px;
    border-radius: $border-radius-small;
}

blockquote {
    border-left: 3px solid $blockquote-border-color;
    font-size: 20px;
    font-style: italic;
    font-family:$font-family-sans-serif-condensed;
}

/*@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-stretch: condensed;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v13/Zd2E9abXLFGSr9G3YK2MsDAdhzWOYhqHvOZMRGaEyPo.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}*/


.panel-group {
    .panel {
        + .panel {
            margin-top: 10px;
        }
    }
}
//Buttons
.button-container {
    position: relative;
}

.btn {
    border-radius: 82px;
    padding: 12px 31px;
    text-transform: uppercase;
    font-weight: 700;
    font-family:$font-family-sans-serif-condensed;
    letter-spacing: 1px;    
    border: 2px solid transparent;

    &:active {
        box-shadow: none;
    }

    &.dropdown-toggle {
        border-radius: 2px;
        width: 100%;
        text-align: left;
        position: relative;
        padding: 10px;
        text-transform: none;
        font-weight: normal;
        font-size: $font-size-h3;
        letter-spacing: normal;

        > .icon {
            position: absolute;
            height: 100%;
            top: 0;
            right: 10px;
            color: $color-accent;
        }
    }
}

.btn-secondary {
    background-color: $color-white;
    color: $color-accent;

    &:hover {
        background-color: $color-accent;
        color: $color-white;
        border-color: $color-white;
    }
}

.btn-attribute {
    border-color: $color-gray;
    color: $color-accent;
    background-color: $color-white;
    padding: 10px 20px;
    font-size: 13px;   

    .icon {
        margin-top: 2px;
        margin-left: 10px;
        width: 11px;
        height: 11px;
    }
}

.btn-attribute:focus{    
        outline:none!important;
    }

.btn-link {
    padding: 0;
    border: 0;
    cursor: pointer;
}

.attributes-container {
    margin-top:15px;
    button{
        margin-bottom: 15px;
    }
}
//Menu

.dropdown {
    .open-icon {
        display: block;
    }

    .close-icon {
        display: none;
    }

    &.open {
        .open-icon {
            display: none;
        }

        .close-icon {
            display: block;
        }

        .arrow {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                border-style: solid;
                border-width: 0 5px 5px;
                border-color: $color-accent transparent;
                display: block;
                width: 0;
                z-index: 1;
                top: 7px;
                left: calc(50% - 5px);
            }
        }
    }

    &.fw {
        position: static;

        .dropdown-menu {
            left: $margin*2;
            right: $margin*2;

            &:after {
                content: none;
            }

            margin-top: ($margin);
            border-top: 5px solid $color-accent;
            padding-top: $margin*3;
            padding-bottom: $margin*3;
        }

        .row {
            .inner {
                padding: $margin (($margin*2)-6) ($margin*2);
            }
        }

        .heading {
            color: $color-accent;
            font-size: 20px;
        }

        li {
            line-height: 1.7;

            a {
                color: $text-color;
            }
        }

        .parent-link {
            font-size: 18px;

            .icon {
                margin-left: $margin;
                margin-top: 1px;
            }
        }
    }
}

.navbar {
    margin-bottom: 0;
}

.navbar-default {
    border: none;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
}

.navbar-message {
    /*height: 25px;
    background-color: $color-accent-dark-extra;
    padding: 0;
    margin-top: 71px;
    text-align: right;
    padding: 2px 15px;
    color: $color-white;
    a, a:hover, a:visited{
        color:$color-white;
    }*/
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Roboto Condensed", sans-serif;
    letter-spacing: 1px;
    color: $color-white;
    margin: 0 15px;
    display: flex;
    align-items: center;
    height: 96px;

    @media (min-width: 992px) and (max-width: $screen-md-max) {
        height: 72px;
    }

    @media (max-width: 1199px) {
        display:none !important;
    }


    p {
        font-size: inherit;
        margin: 0;
    }

    a, a:hover, a:visited {
        color: $color-white;
    }
}

.navbar-nav > li > a {
    @extend .text-uppcase-style;
}

.navbar-nav > li > a, .search-in-nav > .searchbox-wrapper {
    height: 96px;
    @extend .flex-v-center;

    &.small {
        font-size: 11px;
    }
    form{
        width:100%;
    }

    @media (min-width: 992px) and (max-width: $screen-md-max) {
        height: 72px;
    }

    @media (min-width: $screen-sm-min) {
        padding-left: $margin;
        padding-right: $margin;
    }

    @media (min-width: $screen-sm-min) {
        padding-left: $margin*1.5;
        padding-right: $margin*1.5;
    } 

    @media (min-width: ($screen-lg-min + 200)) {
        padding-left: $margin*2;
        padding-right: $margin*2;
    }

    @media (min-width: 992px) {
        border-right: 1px solid $color-accent-dark;
        /*height: 60px;*/
    }

    @media (max-width: 992px) {
        height: 50px;
    }
}

/*.navbar-message-visible {
    .dropdown.open .arrow {
        &:after {
            top: 32px;
        }
    }

    .navbar-nav > li > a {
        height: 71px;
    }
}*/

header.main-header {
    height: 96px;

    @media (max-width: $screen-md-max) {
        height: 72px;
    }
}

header.floating {
    height: auto; // Prevents the banner image from being pushed down to allow the menu to float top of it

    @media (max-width: $screen-md-max) {
        height: 72px;
    }

}

.floating > .navbar-fixed-top {
    @media (min-width: $screen-lg-min) {
        top: 24px;
        right: 24px;
        left: 24px;
    }
}

.navbar-header {
    background-color: #FFF;
    height: 96px;

    @media (max-width: $screen-sm-max) {
        border-bottom: 5px solid $color-accent-dark;
        /*.logo img {
            padding: 2px 0;
        }*/
    }

    @media (max-width: $screen-md-max) {
        height: 72px;

        .logo img {
            max-height: 50px;
            /*width: auto !important;*/
        }

        .logo, img {
            max-height: 50px;
            /*width: auto !important;*/
        }
    }

    @media (min-width: $screen-sm-min) {
        padding-left: $margin;
        padding-right: $margin;
        padding-top: $margin / 2;
        padding-bottom: $margin / 2;
    }

    @media (min-width: $screen-lg-min) {
        padding-left: $margin*2;
        padding-right: $margin*2;
    }

    @extend .flex-v-center;
}

.navbar-nav > li > .dropdown-menu {
    min-width: 230px;
    margin-top: -($margin);
    left: calc(50% - 115px);
    padding: 0;
    border: none;
    .divider {
        margin: 0;
    }

    @media (min-width: 992px) { 
        &:after {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 0 5px 5px;
            border-color: #FFFFFF transparent;
            display: block;
            width: 0;
            z-index: 1;
            top: -5px;
            left: calc(50% - 5px);
        }

        &.dropdown-menu-columns:after {
            right: 22px;
            left: auto;
        }
    }
}

.dropdown-menu.dropdown-menu-lg > li > a {
    padding: ($margin*1.5) $margin;
}

.pagination {
    display: inline-block;

    > li {
        > a,
        > span {
            margin: $margin/2;
        }

        &:first-child {
            > a,
            > span {
                border: none;
            }
        }

        &:last-child {
            > a,
            > span {
                border: none;
            }
        }
    }
}


//Mobile menu and navbar

.main-header .navbar-right { // TODO: Remove this when we have fixed the container markup
    margin: 0;
}

@media (max-width: $screen-xs-max) {
    .container-fluid > .navbar-header {
        margin: 0;
        padding: 0 $padding-base-horizontal;
    }
}

@media (max-width: $screen-sm-max) {
    .navbar-header {
        float: none;
    }

    .navbar-left, .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
        border-radius: 0;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none !important;

        &.in {
            overflow: hidden auto !important;
        }
    }

    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }


    .navbar-nav > li {
        float: none;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collapse.in {
        display: block !important;
    }

    ul.nav.navbar-nav {
        margin: 0;
    }

    ul.nav.navbar-nav li a {
        padding-left: 25px;
    }

    ul.nav.navbar-nav:not(.navbar-right) li {
        border-bottom: 1px solid #ddd;
    }

    ul.nav.navbar-nav.navbar-right {
        /*margin-top: -8px;*/
    }

    ul.nav.navbar-nav.navbar-right li:first-child {
        border-bottom: 1px solid #ddd;
    }
}

.breadcrumb {
    padding-left: 0;
    margin-bottom: 0;
    background-color: transparent;
}

address {
    font-size: ceil($font-size-base*1.1428);

    .company-name {
        margin-bottom: $margin;
    }
}

.btn-default.dropdown-toggle {
    border: 1px solid #ddd;
    color: $gray-light;
    font-size: 16px;
}






//Override bootstrap style

.navbar-default {
    overflow: unset !important;
    background-color: #eeeeee;

    @media (min-width: 992px) {
        background-color: $color-accent;
    }

    @media (max-width: $screen-sm-max) {
        overflow: auto;
    }
}

.navbar-nav {
    > li > a {
        color: $color-accent !important;

        @media (min-width: 992px) {
            color: $navbar-default-link-color !important;
        }


        &:hover,
        &:focus {
            background-color: transparent !important;

            @media (min-width: 992px) {
                color: $navbar-default-link-hover-color;
                background-color: $navbar-default-link-hover-bg;
            }
        }
    }

    > .active > a {
        &,
        &:hover,
        &:focus {
            background-color: transparent !important;

            @media (min-width: 992px) {
                color: $navbar-default-link-active-color;
                background-color: $navbar-default-link-active-bg;
            }
        }
    }

    > .disabled > a {
        &,
        &:hover,
        &:focus {
            background-color: transparent !important;

            @media (min-width: 992px) {
                color: $navbar-default-link-disabled-color;
                background-color: $navbar-default-link-disabled-bg;
            }
        }
    }
}

.navbar-toggle {
    border-color: none;

    &:hover,
    &:focus, &:active {
        background-color: $color-accent-dark !important;
        border-color: transparent !important;
    }
}

.navbar-collapse,
.navbar-form {
    border-color: none;

    @media (min-width: 992px) {
        border-color: $navbar-default-border;
    }
}

.navbar-toggle {
    background: $color-accent;
    border-color: #ddd;
    right: 0;
    position: absolute;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
    /*width: 90%;*/
}


@media (min-width: $screen-md-min) {
    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 100%;
        display: flex !important;
        flex-direction: row;
        align-items: stretch;
    }
    .navbar-default .navbar-nav:not(.navbar-right) {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .search-in-nav {
            flex: 1; 

            .searchbox-wrapper {
                margin: 0;
                border-right: 1px solid $color-accent-dark;
            }

            .search-icon {
                padding-top: 30px;
            }
        }
    }
}

@media (min-width: 1440px) {
    .search-in-nav{
        display:block;
    }
    .search-button-in-nav {
        display: none !important;
    }
}
@media (max-width: 1439px) {
    .search-in-nav {
        display: none !important;
    }

    .search-button-in-nav {
        display: block;
    }
}

.navbar-nav > li > .dropdown-menu:after {
    position: relative;
    left: 0;

    @media (min-width: 992px) {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 5px 5px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -5px;
        left: calc(50% - 5px);
    }
}

.categories-mobile .panel-primary > .panel-heading {
    background: #fff;
}

.categories-mobile .panel-body {
    padding: 0;
}

.categories-mobile .panel-group.one-at-a-time {
    padding-left: 15px;
    padding-right: 15px;
    border-color: #ddd;
}

.categories-mobile .thumbnail {
    border-bottom: none;
}

.categories-mobile .panel-group {
    cursor: pointer;
}

.categories-mobile .panel-group .panel-primary {
    border-color: #ddd;
}

.categories-mobile .thumbnail a > img {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 0;
}
.categories-mobile li a {
    color: #111;
}

.icon-link-color {
    color: $color-accent;
}

.lead{
    p{
        margin-bottom:0;
    }
}