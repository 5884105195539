﻿/// <reference path="../main.scss" />
/**
    Blocks
**/

.accessoryblock {

    .accessoryblock-inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: $margin; 
        margin-bottom: 10px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #e8e8e8;

        > *:not(:last-child) {
            margin-right: 20px;
        }

        @media (max-width: $screen-sm-max) {
            flex-direction: column;
        }
    }

    a.accessoryblock-inner {
        text-decoration: none;
        color: $color-black;
        transition: background-color 150ms linear;

        &:hover {
            background-color: #f8f8f8;
        }
    }

    .accessoryblock-image {
        width: 40%;

        img {
            height: auto;
            width: 100%;
        }

        @media (max-width: $screen-sm-max) {
            width: 100%;
        }
    }

    .accessoryblock-info {
        width: 60%;

        @media (max-width: $screen-sm-max) {
            width: 100%;
            padding-top: $margin;
        }
    }
}

.jumbotronblockcta .inner {
    margin-top: 84px;
    margin-bottom: 96px;
    color: #FFF;

    .subHeader {
        /*font-family: Roboto;*/
        font-size: 32px;
        font-weight: 700;
        font-family:$font-family-sans-serif-condensed;
        font-style: normal;
        line-height: 1.5;
        text-align: center;
        color: #ffffff;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }

    .header {
        /*font-family: Roboto;*/
        font-size: 15px;
        font-weight: 700;
        font-family:$font-family-sans-serif-condensed;
        font-style: normal;
        letter-spacing: 0.9px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }

    > div {
        margin-bottom: 24px;
    }
}

.videowithlightboxblock {
    .play-icon {
        &:hover, &:focus, &:active {        
        outline:0;
        }
    }
}

.textandmediablock, .videoblock {
    .media-box {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #DDD;
        padding: $margin;
        margin-bottom:24px;

        @media (max-width: $screen-xs-max) {
            margin-top: $margin;
        }
    }

    .button {
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }
}

.page-header-large-carousel-block {
    height: 720px;
    background-color: $color-white;
    position: relative;
    z-index:1;
    overflow: hidden;
    margin-bottom: $margin;

    .slider-present {
        margin-bottom: 0;
    }

    .large-carousel-item {
        position: relative;
        height: 720px;
        margin-bottom: $margin;

        .inner {
            color: #FFF;
            /*min-height:436px;*/
            @media (max-width: $screen-xs-max) {
                min-height: 0;
            }
        }

        .header-background {
            margin-top: 220px;

            @media (max-width: $screen-xs-max) {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            background-color: rgba(255,255,255,0.8);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
        }

        .header { //p fix for unflexible TinyMCE
            padding: 48px;
            font-size: $font-size-base*2.2;
            font-family:$font-family-sans-serif-condensed;
            font-weight: 700;
            
            text-align: left;
            color: $color-black;

            p { //p fix for unflexible TinyMCE
                font-size: $font-size-base*2.2;
                margin: 0;
                line-height: 1.3333;
            }

            .btn {
                margin-top: $margin*1.5;
            }
        }
    }

    .carousel-nav-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: $margin*1.5;
        height: 158px;

        .init-slider-nav {
            display: none;

            .slick-track {
                margin-top: 5px;
            }
        }

        .slick-initialized {
            display: block;
        }

        .slick-prev, .slick-next {
            top: calc(50%); //Half of margin-bottom.
        }

        @media (max-width: $screen-sm-max) {
            .slick-prev {
                left: -15px;
            }

            .slick-next {
                right: -15px;
            }
        }
    }

    .carousel-nav-item {
        background-color: $color-white;
        padding: 12px;
        margin: 0 14px;

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }

        a{ 
            &:focus {
            outline: none;
            }
        }
    }

    .slick-current {
        &.carousel-nav-item {
            border-top: 5px solid $color-accent;
            padding-top: 7px;

            .arrow {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 0 5px 5px;
                    border-color: $color-accent transparent;
                    display: block;
                    width: 0;
                    z-index: 1;
                    top: -17px;
                    left: calc(50% - 9px);
                }
            }
        }
    }

    .text-container {
        justify-content: center;
        align-items: center;
    }

    .numbers-bg {
        color: $color-gray-light-light;
        font-size: 100px;
        font-weight: 900;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;

        @media (max-width: $screen-md-max) {
            font-size: 75px;
        }

        @media (max-width: $screen-sm-max) {
            font-size: 85px;
        }
    }

    .headline {
        z-index: 10;
        font-size: 15px;
        font-weight: bold;
    }

    .slick-current {
        .headline {
            color: $color-accent;
        }
    }
}

.page-header-large-carousel-block-compact {
    .item {
        margin: 10px 0;
        padding: 5px;
        background-color: $color-white;
    }

    .text-container {
        font-size: 15px;
        color: currentColor;

        * {
            color: currentColor;
        }
    }

    .icon {
        position: absolute;
        right: 15px;
    }
}

.pagebannerblock {
    margin-bottom: $margin;

    .inner {
        color: #FFF;
        min-height: 436px;

        @media (max-width: $screen-xs-max) {
            min-height: 0;
        }
    }

    .header-background {
        margin-top: 48px;
        margin-bottom: 48px;

        @media (max-width: $screen-xs-max) {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        background-color: rgba(255,255,255,0.8);
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .header { //p fix for unflexible TinyMCE
        padding: 48px;
        /*font-family: Roboto;*/
        font-size: 32px;
        font-weight: 700;
        font-family: $font-family-sans-serif-condensed;
        font-style: normal;
        color: #111111;
        /*text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        float:none;
        margin: 0 auto; */
        p { //p fix for unflexible TinyMCE
            font-size: 32px;
            margin: 0;
            line-height: 1.3333;

            @media (max-width: $screen-xs-max) {
                font-size: 25px;
            }
        }
    }
}

.aligner {
    @supports (display: flex) {
        display: flex;
    }
    align-items: center;
}

/*.newsletterblock .round {     
        border-radius: 60px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
        background-color:  #ffffff;        
        height: 60px;
        text-align: center;
        @media (max-width: $screen-xs) {
            background-color: Yellow;
            }        
    }*/



.round-left {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    height: 60px;


    @media (max-width: $screen-sm) {
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
        margin-bottom: 10px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    }

    @media (max-width: $screen-xs) {
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
        margin-bottom: 10px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    }
}

.round-right {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    box-shadow: 8px 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    height: 60px;
    padding-top: 6px;
    padding-right: 0px;

    @media (max-width: $screen-sm) {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    }

    @media (max-width: $screen-xs) {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    }
}


#btn-submit-newsletter .icon-md {
    display: block;
}


#btn-submit-newsletter {
    @media (max-width: $screen-xs) {
        width: 150px;
        display: block;
        margin-top: 80px;
    }
}

.newsletterblock__signup {
    margin-top: 12px;
    margin-right: 15px;

    span {
        font-family: Roboto;
        font-size: 15px;
        padding-left: 5px;
        color: #111111;
    }
}

.btn-rounded {
    border-radius: 60px;
    width: 48px;
    height: 48px;
    background-color: $color-accent;
    color: $color-white;
    float: right;
    margin-right: 6px;
    box-shadow: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

    @media (max-width: $screen-xs) {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}


.newsletterblock {
    .outer {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .inner {
        margin-top: 84px;
        margin-bottom: 96px;
        color: #FFF;
        padding: 0 15px;

        .subHeader {
            /*font-family: Roboto;*/
            font-size: 32px;

            @media (max-width: $screen-xs) {
                font-size: $font-size-h3;
            }

            font-weight: 700;
            font-family: $font-family-sans-serif-condensed;
            font-style: normal;
            line-height: 1.5;
            text-align: center;
            color: #ffffff;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            margin-bottom: 33px;
        }

        .header {
            /*font-family: Roboto;*/
            font-size: 15px;
            font-weight: 700;
            font-family: $font-family-sans-serif-condensed;
            font-style: normal;
            letter-spacing: 0.9px;
            text-align: center;
            color: #ffffff;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            margin-bottom: 33px;
        }

        .newsletterblock > div {
            margin-bottom: 24px;
        }

        .input-box {
            height: 48px;
            line-height: 48px;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            color: #999999;
            font-family: $font-family-sans-serif !important;
        }

        .input-box-align {
            padding: 3px;
            height: 60px;
            display: inline-block;
        }

        .left-border {
            border-right: solid 1px #dddddd;
        }

        .left-input-textbox {
            border: none;
            width: 96%;
            margin-left: 16px;
            background: none;
        }

        .middle-input-textbox {
            border: none;
            width: 100%;
        }

        .email-input-textbox {
            border: none;
            margin-left: 15px !important;
            width: 75%;
            float: left;

            @media (min-width: $screen-xs-max) {
                margin-left: 0px !important;
            }
        }

        .left-input-textbox:focus {
            outline: none;
        }

        .middle-input-textbox:focus {
            outline: none;
        }

        .email-input-textbox:focus {
            outline: none;
        }

        button:focus {
            outline: none;
        }


        .pdf-newsletterform-error {
            border: solid 1px red !important;
            //border-color:red !important;
        }

        .btn-link:hover, .btn-link:focus, .btn-link:active {
            background-color: $color-accent-dark;
            color: $color-white;
        }

        .field-splitter {
            border-right: solid 1px #dddddd;
            padding-top: 6px;
            height: 60px;

            @media (max-width: $screen-xs-max) {
                &.middle {
                    border: none;
                }
            }
        }

        .icon-md {
            margin-left: auto;
            margin-right: auto;
        }

        .inner-html-block {
            text-align: center;
            padding-top: 33px;

            a {
                color: $color-gray-light-light;
                text-decoration: underline;
            }
        }
    }
}

.cookie-info {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1040;
    background-color: $color-accent;
    color: $color-white;
    text-align: center;
    padding: $margin*2;

    a {
        color: $color-gray-light-light;
        text-decoration: underline;
    }
}


.contactuswideblock {
    padding: $margin*2 0;
    font-size: 20px;

    @media (max-width: $screen-sm-max) {
        font-size: $font-size-base;
    }

    
    font-weight: 700;
    font-family:$font-family-sans-serif-condensed;
    color: $color-white;

    .btn {
        padding: 12px 56px;

        @media (max-width: $screen-sm-max) {
            margin-top: 20px;
        }
    }
}

.pagelinkcarouselblock {
    &.external-page-links {
        a{
            color:$color-black;
        } 
        button {
            margin-top: 10px; 
        }
    }

    .slick-track {
        display: flex;
    }

    .headline {
        margin-bottom: $margin*3;
    }

    .carousel-item {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px $color-gray;
        padding: 6px;
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;
    }

    .carousel-item-inner {
        background-color: $color-gray-light;
        /*padding: 25px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        height: 179px;
        overflow: hidden;
    }

    a {
        img {
            transition: all 0.5s ease;
        }

        &:hover {
            img {
                transform: scale(1.1); /* Safari and Chrome */
            }
        }
    }

    .init-standard-multi-carousel-mobile {
        .slick-list {
            border: solid 1px $color-gray;
            //border-right: solid 1px $color-gray;
        }
    }

    .caption {
        margin: $margin*2 0;
        font-size: 16px;
        font-weight: bold;
    }

    .carousel-item-small {
        margin: 0;
        //border:none;
        border-color: #FFF;

        &.slick-active {
            border-left: solid 1px $color-gray;

            &.slick-current {
                border-color: #FFF;
            }
        }
        /*.carousel-item-inner{
            height: 480px;
        }*/
    }

    .slick-list.draggable {
        @media (min-width: $screen-lg-min) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    .slick-arrow {
        background: none;
    }
    /*.slick-prev{
        left:-13px;
    }
    .slick-next{
        right:-13px;
    }*/
}


.quotecarouselblock {
    .slick-track {
        display: flex;
    }

    .headline {
        margin-bottom: $margin*3;
    }

    .carousel-item {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
        /*border: solid 1px $color-gray;*/
        padding: 6px;
        margin-left: 10px;
        margin-right: 10px;
        height: auto !important;
    }

    .carousel-item-inner {
        background-color: $color-gray-light;
        /*padding: 25px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        /*height: 179px;*/
        overflow: hidden;
    }

    a {
        img {
            transition: all 0.5s ease;
        }

        &:hover {
            img {
                transform: scale(1.1); /* Safari and Chrome */
            }
        }
    }

    .init-standard-multi-carousel-mobile {
        .slick-list {
            //border: solid 1px $color-gray;
            //border-right: solid 1px $color-gray;
        }
    }

    .caption {
        margin: $margin*2 0;
        font-size: 16px;
        font-weight: bold;
    }

    .carousel-item-small {
        margin: 0;
        //border:none;
        //border-color: #FFF;

        /*&.slick-active {
            border-left: solid 1px $color-gray;

            &.slick-current {
                border-color: #FFF;
            }
        }*/
        /*.carousel-item-inner{
            height: 480px;
        }*/
    }

    .slick-list.draggable {
        @media (min-width: $screen-lg-min) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    .slick-arrow {
        background: none;
    }
    /*.slick-prev{
        left:-13px;
    }
    .slick-next{
        right:-13px;
    }*/
    .slick-arrow-lg.slick-prev{
        left:-25px;
    }

    .slick-arrow-lg.slick-next{
        right:-25px;
    }
}

.socialmediablock {
    font-size: 16px;

    .splash {
        font-size: 34px;
        font-weight: 700;
        font-family: $font-family-sans-serif-condensed;
        text-align: center;
        width: 100%;
        color: $color-white;
    }

    .splash-small {
        font-size: 22px;
        font-weight: 700;
        font-family: $font-family-sans-serif-condensed;
        color: $color-white;
    }

    .socialmedia-square {
        height: 306px;
        border: solid 2px $color-white;
        background-color: #eeeeee;
        padding: $margin*2;

        > .social-media-icon {
            position: absolute;
            left: 0;
            bottom: 0;
            margin: 16px;
            font-size: 32px;
        }

        &.socialmedia-square-facebook {
            > div { 
                overflow: hidden;
                max-height: 70%;
            }
        }
    }

    a, a:hover {
        .socialmedia-square {
            .social-media-icon {
                color: $color-black;
            }
        }
    }

    .socialmedia-square-headline {
        margin-bottom: $margin;

        @media (min-width: $screen-lg-min) {
            margin-bottom: $margin*2;
        }

        display: block;
    }

    .social-media-square-1 {
        background-color: $color-accent;
        padding: 0;

        li {
            margin-bottom: 5px;
        }

        a {
            color: $color-white;
        }

        .icon {
            vertical-align: middle;
            margin-right: 8px;
        }

        &.large-padding {
            padding: $margin*4;
            padding-bottom: 0;
        }
    }
}

.search-child-pages-with-attributes-block, .searchchildpageswithattributesblock, .product-overview-carousel {
    .product-filter-img-bkg {
        background-color: #f2f2f2;
        //padding: 20px;
    }

    .product-filter-frame {
        background-color: #ffffff;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #dddddd;
        padding: 5px;
        flex: 1 1 auto;

        &:hover {
            transition: all 0.25s;
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        }
    }

    .product-filter-item {
        margin-bottom:20px;
        .text-container {
            margin: $margin*2;
        }

        .heading {
            font-size: $font-size-h3;
            color: $color-accent;
            font-weight: 700;
            font-family:$font-family-sans-serif-condensed;
            margin-bottom:12px;
        }
    }

    .product-filter-items {
        button {
            margin-top: $margin*4;
        }
    }
}

.smalldividerblock {
    .left {
        height: 12px;
        background-color: $color-accent;
    }

    .right {
        width: 10px;
        background-color: $color-white;
        height: 12px;
        /*margin-left: -10px;*/
    }
}

.linkandlatestnewsblock { //.link-and-latest-news-block
    .date-box {
        padding: 12px 20px;
        position: absolute;
        top: -2px;
        left: calc(50% - 33px);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        /* right: 50%; */
        background-color: $color-accent;
        color: $color-white;
        line-height: 1.2;

        .date {
            font-size: 24px;
        }

        a{ 
            &:focus {
            outline: none;
            }
        }

    }

    .divider-line {
        margin-left: -$margin;
        margin-right: -$margin;
    }

    .divider-line-gray {
        padding: 0 $margin;
        margin-bottom: $margin;
    }

    .headline {
        &.hedline-padded {
            margin: 6px $margin 18px;
        }

        margin-bottom: 18px;
    }

    .indented {
        margin-left: 44px;
    }

    li .icon {
        position: absolute;
        left: 0;
        color: $color-accent;
    }

    .icon-link-list li {
        margin-bottom: 20px;
        font-weight: bold;
    }
}

.category-frame {
    li a {
        color: $color-black;
    }
}

.navigationcarouselblock {
    background: #fff;
    padding: 10px 10px 0 10px;
}




//Socialmedia


.top .socialmedia-content-item, .second .socialmedia-content-item {
    @media only screen and (min-width : 320px) {
        width: 100%;
    }

    @media only screen and (min-width : 480px) {
        width: 100%;
    }

    @media only screen and (min-width: 768px) {
        width: 50% !important;
    }

    @media only screen and (min-width : 992px) {
        width: 25% !important;
    }

    @media only screen and (min-width: 1120px) {
        width: 25% !important;
    }
}



.col-sm-3.socialmedia-square.social-media-square-1.socialmedia-square-headline.large-padding.social-media-container {

    @media only screen and (min-width: 768px) {
        width: 100% !important;
    }

    @media (min-width: 992px) {
        width: 25% !important;
    }

    @media (min-width:1120px) {
        width: 25%;
    }
}

@media (max-width: 768px) {
    .social-media-container {
        height: 100%;
        padding-bottom: 48px;

        .list-unstyled {
            display: flex;
        }
    }

    .social-media-icon-span span {
        display: none;
    }
}

.social-media-icon-mobile {
    display: none;
}

#twitter, #facebook, #instagram {
    display: block;
}


.none-mobile {
    display: block;
}

.mobile-social-share {
    padding: 15px !important;
    height: auto !important;
    /*ul {
        margin-left: 240px;

        li {
            padding-right: 15px;
        }
    }*/
}

.mobile-social-share-headline {
    text-align: center;
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
}

.splash-mobile {
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 26px !important;
}

.social-media-expander-divs {
    background: #fff;
    padding-top: 20px;
}

.social-media-content > ul li {
    position: relative;
    top: calc(50% - 10px);
    left: calc(50% - 80px) !important;
}

@media (max-width: 480px) {
    .socialmedia-content-item {
        height: auto !important;
    }

    .socialmedia-content-item .panel-collapse {
        background: #eee;
    }

    .social-media-icon-span span {
        display: none;
    }

    .social-media-icon {
        display: none;
    }

    .social-media-icon-mobile {
        display: block !important;
        height: 40px;
        padding: 10px;
        background: #eee;
        border: solid 1px #dddddd;
        margin-bottom: 0px;

        a span {
            color: #111;
        }
    }

    .none-mobile {
        display: none;
    }

    .mobile-social-share {

        ul {
            margin-left: 65px;
        }
    }

    .social-media-content > ul li {
        position: relative;
        top: calc(50% - 10px);
        left: calc(50% - 110px) !important;
    }
}

.social-media-content {
    text-align: center;
}

.raw-html-block{
    display: flex;
    justify-content: center;
    margin-bottom:12px;
}

.attribute-item-header{
    display: block;
    padding: 3px 10px;
    clear: both;
    font-weight: bold;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
}

.quote-image-item{
    margin-bottom:7px;
}